.landing-page-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.landing-page-top-container {
  width: 100%;
  height: 844px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url('https://img.freepik.com/fotos-kostenlos/sonnenaufgang_74190-169.jpg?w=1800&t=st=1665343839~exp=1665344439~hmac=c88d15666df52d13590e5c8795c957f4d374e8b5f08b52aff6fb69fa1d0f07ee');
}
.landing-page-navbar {
  width: 100%;
  height: 165px;
  display: flex;
  max-width: 100%;
  align-self: flex-end;
  align-items: center;
  margin-left: 1px;
  padding-top: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: flex-end;
}
.landing-page-logo {
  color: #50584d;
  width: 1049px;
  font-size: 2em;
  align-self: center;
  box-shadow: 5px 5px 5px 2px rgb(212, 212, 212);
  text-align: center;
  font-family: Exo 2;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  text-transform: uppercase;
  background-image: linear-gradient(90deg, rgb(241, 242, 181) 0.00%,rgb(141, 172, 143) 100.00%);
  border-top-left-radius: var(--dl-radius-radius-radius8);
}
.landing-page-right-side {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.landing-page-link {
  color: rgb(194, 197, 174);
  box-shadow: rgb(118, 132, 111) 2px 2px 5px 0px;
  text-align: center;
  margin-left: var(--dl-space-space-halfunit);
  border-width: 0px;
  margin-right: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius8);
  text-decoration: none;
  background-color: rgb(78, 86, 82);
}
.landing-page-burger-menu {
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.landing-page-burger-menu {
  width: 24px;
  height: 24px;
}
.landing-page-mobile-menu {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  width: 100%;
  height: 100%;
  display: none;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.landing-page-nav {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.landing-page-top {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}
.landing-page-close-menu {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.landing-page-icon03 {
  width: 24px;
  height: 24px;
}
.landing-page-right-side1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.landing-page-links-container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
  flex-direction: column;
  justify-content: space-between;
}
.landing-page-text {
  margin-bottom: 8px;
}
.landing-page-text01 {
  margin-bottom: 8px;
}
.landing-page-text02 {
  margin-bottom: 8px;
}
.landing-page-link01 {
  display: contents;
}
.landing-page-follow-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.landing-page-text04 {
  padding-bottom: var(--dl-space-space-halfunit);
}
.landing-page-icons-container {
  width: 100px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.landing-page-link02 {
  display: contents;
}
.landing-page-icon05 {
  width: 24px;
  height: 24px;
}
.landing-page-link03 {
  display: contents;
}
.landing-page-icon07 {
  width: 24px;
  height: 24px;
}
.landing-page-link04 {
  display: contents;
}
.landing-page-icon09 {
  width: 24px;
  height: 24px;
}
.landing-page-link05 {
  color: rgb(194, 197, 174);
  box-shadow: rgb(118, 132, 111) 2px 2px 5px 0px;
  text-align: center;
  margin-left: var(--dl-space-space-halfunit);
  border-width: 0px;
  margin-right: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius8);
  text-decoration: none;
  background-color: rgb(78, 86, 82);
}
.landing-page-link06 {
  color: rgb(194, 197, 174);
  width: 279px;
  box-shadow: rgb(118, 132, 111) 2px 2px 5px 0px;
  text-align: center;
  margin-left: var(--dl-space-space-halfunit);
  border-width: 0px;
  margin-right: var(--dl-space-space-halfunit);
  padding-left: 0px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 0px;
  text-decoration: none;
  background-color: rgb(78, 86, 82);
}
.landing-page-iframe {
  width: 80%;
  height: 457px;
  align-self: center;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
}
.landing-page-main {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-quadruple);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-quadruple);
  background-size: cover;
  justify-content: flex-start;
  background-image: url('https://img.freepik.com/fotos-kostenlos/gruener-abstrakter-hintergrund-und-len-flare_1323-222.jpg?w=1800&t=st=1665335679~exp=1665336279~hmac=845eab1ad9a953f6ab04da4807987244c7488bbd5da05f1493b5efb427bb49d1');
}
.landing-page-text05 {
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
}
.landing-page-link07 {
  text-decoration: underline;
}
.landing-page-text07 {
  color: rgb(61, 60, 60);
  font-style: normal;
  font-weight: 300;
}
.landing-page-text08 {
  padding-top: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-doubleunit);
}
.landing-page-container01 {
  width: 110%;
  height: 929px;
  display: flex;
  align-items: flex-start;
  background-size: cover;
  background-image: url('https://img.freepik.com/fotos-kostenlos/die-leeren-straende-im-fruehjahr_181624-27624.jpg?w=1800&t=st=1665344138~exp=1665344738~hmac=6d1999178ded44a48eb7504fe8e5b59e7663db87eed987c354ceef11085c974e');
}
.landing-page-tour-details-container {
  width: 100%;
  display: flex;
  grid-gap: var(--dl-space-space-doubleunit);
  flex-wrap: wrap;
  max-width: 1000px;
  min-width: 100%;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}
.landing-page-text29 {
  width: 100%;
  align-self: center;
  text-align: center;
}
.landing-page-container02 {
  flex: 0 0 auto;
  width: 300px;
  height: auto;
  display: flex;
  position: relative;
  align-items: space-between;
  flex-direction: column;
}
.landing-page-link08 {
  display: contents;
}
.landing-page-component01 {
  text-decoration: none;
}
.landing-page-link09 {
  color: rgb(61, 88, 44);
  width: 136px;
  align-self: center;
  box-shadow: rgb(175, 168, 168) 2px 2px 5px 0px;
  text-align: center;
  font-family: Poppins;
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius8);
  text-transform: capitalize;
  text-decoration: none;
  background-color: rgb(238, 247, 227);
}
.landing-page-container03 {
  flex: 0 0 auto;
  width: 300px;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.landing-page-container04 {
  flex: 0 0 auto;
  width: 300px;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.landing-page-link10 {
  display: contents;
}
.landing-page-component02 {
  text-decoration: none;
}
.landing-page-link11 {
  color: rgb(61, 88, 44);
  width: 136px;
  align-self: center;
  box-shadow: rgb(175, 168, 168) 2px 2px 5px 0px;
  text-align: center;
  font-family: Poppins;
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius8);
  text-transform: capitalize;
  text-decoration: none;
  background-color: rgb(238, 247, 227);
}
.landing-page-container05 {
  flex: 0 0 auto;
  width: 300px;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.landing-page-link12 {
  display: contents;
}
.landing-page-component03 {
  text-decoration: none;
}
.landing-page-link13 {
  color: rgb(61, 88, 44);
  width: 136px;
  align-self: center;
  box-shadow: rgb(175, 168, 168) 2px 2px 5px 0px;
  text-align: center;
  font-family: Poppins;
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius8);
  text-transform: capitalize;
  text-decoration: none;
  background-color: rgb(238, 247, 227);
}
.landing-page-container06 {
  flex: 0 0 auto;
  width: 300px;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.landing-page-link14 {
  display: contents;
}
.landing-page-component04 {
  text-decoration: none;
}
.landing-page-link15 {
  color: rgb(61, 88, 44);
  width: 136px;
  align-self: center;
  box-shadow: rgb(175, 168, 168) 2px 2px 5px 0px;
  text-align: center;
  font-family: Poppins;
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius8);
  text-transform: capitalize;
  text-decoration: none;
  background-color: rgb(238, 247, 227);
}
.landing-page-container07 {
  flex: 0 0 auto;
  width: 300px;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.landing-page-link16 {
  display: contents;
}
.landing-page-component05 {
  text-decoration: none;
}
.landing-page-link17 {
  color: rgb(61, 88, 44);
  width: 136px;
  align-self: center;
  box-shadow: rgb(175, 168, 168) 2px 2px 5px 0px;
  text-align: center;
  font-family: Poppins;
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius8);
  text-transform: capitalize;
  text-decoration: none;
  background-color: rgb(238, 247, 227);
}
.landing-page-container08 {
  flex: 0 0 auto;
  width: 300px;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.landing-page-link18 {
  display: contents;
}
.landing-page-component06 {
  text-decoration: none;
}
.landing-page-link19 {
  color: rgb(61, 88, 44);
  width: 136px;
  align-self: center;
  box-shadow: rgb(175, 168, 168) 2px 2px 5px 0px;
  text-align: center;
  font-family: Poppins;
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius8);
  text-transform: capitalize;
  text-decoration: none;
  background-color: rgb(238, 247, 227);
}
.landing-page-container09 {
  flex: 0 0 auto;
  width: 300px;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.landing-page-link20 {
  display: contents;
}
.landing-page-component07 {
  text-decoration: none;
}
.landing-page-link21 {
  color: rgb(61, 88, 44);
  width: 136px;
  align-self: center;
  box-shadow: rgb(175, 168, 168) 2px 2px 5px 0px;
  text-align: center;
  font-family: Poppins;
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius8);
  text-transform: capitalize;
  text-decoration: none;
  background-color: rgb(238, 247, 227);
}
.landing-page-container10 {
  width: 110%;
  height: 929px;
  display: flex;
  align-items: flex-start;
  background-size: cover;
  background-image: url('https://img.freepik.com/fotos-kostenlos/die-leeren-straende-im-fruehjahr_181624-27624.jpg?w=1800&t=st=1665344138~exp=1665344738~hmac=6d1999178ded44a48eb7504fe8e5b59e7663db87eed987c354ceef11085c974e');
}
.landing-page-text30 {
  width: 100%;
  height: 111px;
  align-self: center;
  text-align: center;
}
.landing-page-faehren-container {
  width: 100%;
  display: flex;
  grid-gap: var(--dl-space-space-doubleunit);
  flex-wrap: wrap;
  max-width: 1000px;
  min-width: 100%;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}
.landing-page-container11 {
  flex: 0 0 auto;
  width: 300px;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.landing-page-link22 {
  display: contents;
}
.landing-page-component08 {
  text-decoration: none;
}
.landing-page-container12 {
  flex: 0 0 auto;
  width: 300px;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.landing-page-container13 {
  flex: 0 0 auto;
  width: 300px;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.landing-page-link23 {
  display: contents;
}
.landing-page-component09 {
  text-decoration: none;
}
.landing-page-container14 {
  flex: 0 0 auto;
  width: 300px;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.landing-page-link24 {
  display: contents;
}
.landing-page-component10 {
  text-decoration: none;
}
.landing-page-container15 {
  flex: 0 0 auto;
  width: 300px;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.landing-page-link25 {
  display: contents;
}
.landing-page-component11 {
  text-decoration: none;
}
.landing-page-container16 {
  width: 110%;
  height: 929px;
  display: flex;
  align-items: flex-start;
  background-size: cover;
  background-image: url('https://img.freepik.com/fotos-kostenlos/die-leeren-straende-im-fruehjahr_181624-27624.jpg?w=1800&t=st=1665344138~exp=1665344738~hmac=6d1999178ded44a48eb7504fe8e5b59e7663db87eed987c354ceef11085c974e');
}
.landing-page-text33 {
  width: 100%;
  height: 111px;
  align-self: center;
  text-align: center;
}
.landing-page-an-und-abreise {
  display: flex;
  grid-gap: var(--dl-space-space-doubleunit);
  flex-wrap: wrap;
  max-width: 1000px;
  min-width: 100%;
  align-self: center;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}
.landing-page-link26 {
  display: contents;
}
.landing-page-component12 {
  text-decoration: none;
}
.landing-page-container17 {
  flex: 0 0 auto;
  width: 300px;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.landing-page-link27 {
  display: contents;
}
.landing-page-component13 {
  text-decoration: none;
}
.landing-page-container18 {
  flex: 0 0 auto;
  width: 300px;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.landing-page-link28 {
  display: contents;
}
.landing-page-component14 {
  text-decoration: none;
}
.landing-page-link29 {
  display: contents;
}
.landing-page-component15 {
  text-decoration: none;
}
.landing-page-footer {
  color: var(--dl-color-gray-white);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-tripleunit);
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-black);
}
.landing-page-menu {
  width: 100%;
  display: flex;
  max-width: 1000px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.landing-page-follow-container1 {
  display: flex;
  align-items: center;
  margin-left: 50px;
  flex-direction: column;
  justify-content: space-between;
}
.landing-page-text36 {
  padding-bottom: var(--dl-space-space-halfunit);
}
.landing-page-icons-container1 {
  width: 100px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.landing-page-link30 {
  display: contents;
}
.landing-page-icon11 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
}
@media(max-width: 991px) {
  .landing-page-tour-details-container {
    justify-content: center;
  }
  .landing-page-faehren-container {
    justify-content: center;
  }
  .landing-page-an-und-abreise {
    justify-content: center;
  }
}
@media(max-width: 767px) {
  .landing-page-right-side {
    display: none;
  }
  .landing-page-burger-menu {
    display: flex;
  }
  .landing-page-main {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .landing-page-menu {
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .landing-page-follow-container1 {
    margin-left: 0px;
  }
}
